<template>
  <div id="reg_step_1">
    <div class="nk-block-head text-center">
      <div class="nk-block-head-content">
        <h4 class="nk-block-title m-0 fw-bold nk-text-dark">{{ $t('Register as an advisor') }}</h4>
        <div class="nk-block-des mt-1 mb-2">
          <p class="fw-500 nk-text-muted small">{{ $t('registration.advise_google_login') }}</p>
        </div>
        <nk-button type="light" outline class="w-65 text-center justify-content-center" v-on:click="signInWithGoogle">
          <img :src="require('@/assets/images/logos/g.png')" alt="Google" style="width: 24px" class="mr-2"/> {{ $t('Login with Google') }}
        </nk-button>
      </div>
    </div>
    <div class="separator text-muted mb-2" v-if="!registrationData.google_id.length && googleAuthLoaded">&ensp;&ensp;{{$t('OR')}}&ensp;&ensp;</div>
    <form action="" @submit.prevent="">
      <form-group>
        <row>
          <column md="6" class="mb-3 mb-md-0">
            <label class="form-label" for="f_name">{{ $t('First name') }}</label>
            <input type="text" class="form-control form-control-lg" id="f_name" placeholder="John" v-model="registrationData.first_name">
          </column>
          <column md="6">
            <label class="form-label" for="l_name">{{ $t('Last name') }}</label>
            <input type="text" class="form-control form-control-lg" id="l_name" placeholder="Doe" v-model="registrationData.last_name">
          </column>
        </row>
      </form-group>
      <form-group>
        <row>
          <column md="6" class="mb-3 mb-md-0">
            <label class="form-label" for="password">{{ $t('Password') }}</label>
            <div class="form-control-wrap">
              <a href="javascript:;" class="form-icon form-icon-right passcode-switch" @click="togglePasswordVisibility(1, !password1Visible)" tabindex="-1">
                <nio-icon icon="ni-eye" v-if="!password1Visible"></nio-icon>
                <nio-icon icon="ni-eye-off" v-else></nio-icon>
              </a>
              <input :type="password1Visible ? 'text': 'password'"
                     class="form-control form-control-lg"
                     id="password" placeholder="********"
                     v-model="registrationData.password"
                     autocomplete="off"/>
            </div>
          </column>
          <column md="6">
            <label class="form-label" for="r_password">{{ $t('Repeat password') }}</label>
            <div class="form-control-wrap">
              <a href="javascript:;" class="form-icon form-icon-right passcode-switch" @click="togglePasswordVisibility(2, !password2Visible)" tabindex="-1">
                <nio-icon icon="ni-eye" v-if="!password2Visible"></nio-icon>
                <nio-icon icon="ni-eye-off" v-else></nio-icon>
              </a>
              <input :type="password2Visible ? 'text': 'password'"
                     class="form-control form-control-lg"
                     id="r_password"
                     placeholder="********"
                     v-model="registrationData.c_password"
                     autocomplete="off"/>
            </div>
          </column>
        </row>
      </form-group>
      <form-group>
        <row>
          <column md="6" class="mb-3 mb-md-0">
            <label class="form-label" for="email">{{ $t('Email') }}</label>
            <input type="email" class="form-control form-control-lg" id="email" placeholder="johndoe@gmail.com" v-model="registrationData.email" autocomplete="off">
          </column>
        </row>
      </form-group>
      <form-group class="mt-4">
        <nk-button type="dark" size="lg" class="btn btn-lg btn-dark btn-block" @click="validateStep1Data()">{{$t('To Step 2')}}</nk-button>
      </form-group>
    </form>
    <div class="form-note-s2 text-center pt-4 fw-500 text-muted">{{ $t('Already have an account?') }} <router-link :to="{name: 'Login'}">{{$t('Sign-In')}}</router-link></div>
  </div>
</template>

<script>
import {Column, FormGroup, Row} from "@core/layouts"
import {onMounted, ref} from "vue";
import { useStore } from "vuex"
import {isPlatform, toastController, /*loadingController*/} from "@ionic/vue"
import {useI18n} from "vue-i18n";
import useCommonFunc from "@core/comp-functions/common"
import {GoogleAuth} from "@codetrix-studio/capacitor-google-auth"

export default {
  components: {
    Column,
    FormGroup,
    Row,
  },
  setup(props, {emit}){

    const i18n = useI18n()
    const store = useStore()
    let registrationData = store.state.advisor_reg.registration_data

    let password1Visible = ref(false)
    let password2Visible = ref(false)
    const{passwordValidationRegex} = useCommonFunc()

    const togglePasswordVisibility = (oneOrTwo, status) => (oneOrTwo === 1 ? password1Visible.value = status : password2Visible.value = status)
    const validateStep1Data = () => {

      let email_reg = /^\S+@\S+$/;
      if(registrationData.first_name.length < 1 || registrationData.last_name.length < 1){
        toastController.create({color: 'danger', message: i18n.t('registration.name_required'), duration:2000}).then( toast => toast.present())
      }
      else if(!passwordValidationRegex.test(registrationData.password)  && !registrationData.google_id.length){
        toastController.create({color: 'danger', message: i18n.t('invalid_password'), duration: 3500}).then( toast => toast.present())
      }
      else if( registrationData.password !== registrationData.c_password){
        toastController.create({color: 'danger', message: i18n.t('registration.password_must_match'), duration:2000}).then( toast => toast.present())
      }
      else if( !email_reg.test( registrationData.email)){
        toastController.create({color: 'danger', message: i18n.t('registration.provide_valid_email'), duration:2000}).then( toast => toast.present())
      }
      else{
        emit('gotoStep', 2)
      }
    }
    onMounted(()=>{
      if(!isPlatform('hybrid')){
        GoogleAuth.init()
      }
    })
    const signInWithGoogle = async () => {
      const response = await GoogleAuth.signIn();
      if(response.id){
        registrationData.google_id_token = response.authentication.idToken
        registrationData.first_name = response.givenName
        registrationData.last_name  = response.familyName
        registrationData.email      = response.email
        registrationData.google_id  = response.id
        registrationData.dp         = response.imageUrl
        emit('gotoStep', 2)
      }
    }

    return {
      togglePasswordVisibility,
      password1Visible,
      password2Visible,
      registrationData,
      validateStep1Data,
      signInWithGoogle,
    }
  }
}
</script>

<style scoped>
.separator {
  display: flex;
  align-items: center;
  text-align: center;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #b6c6e3;
}

.separator:not(:empty)::before {
  margin-right: .25em;
}

.separator:not(:empty)::after {
  margin-left: .25em;
}
.dark-mode .nk-block-title{
  color: #eceef3!important;
}
</style>
